import { atom } from 'jotai';
import { atomWithImmer } from 'jotai-immer'
import dayjs from 'dayjs';

export const defaultDateRange = { from: dayjs().subtract(8, 'weeks').format('YYYY-MM-DD'), to: dayjs().format('YYYY-MM-DD') };

export const mapState = atom([]);

export const currentUserState = atom();

export const userNameState = atom((get) => get(currentUserState)?.username);

export const deviceInfoState = atom(null);

export const allScanDataState = atom([]);

export const dateRangeState = atom(defaultDateRange);

export const isAdminState = atom(false);

export const massSpectrometryTableDataState = atomWithImmer([]);

export const massSpectrometryResultsDataState = atomWithImmer({});

export const clientCodesState = atomWithImmer([]);

export const massSpectrometryTableExpandedRowState = atom([]);

export const traceMapState = atomWithImmer([]);

export const isSubstanceInfoModalOpenState = atom(false);

export const infoModalSubstanceState = atom(null);

export const allSubstancesDataState = atom([]);

export const currentMassSpecIDState = atom(null);

export const massSpecTourOpenState = atom(false);

export const isImageModalOpenState = atom(false);

export const isNewRecordCreatedState = atom(false);

export const imageFilenameState = atom(null);

export const mlOutputFilterIndicesState = atom([]);

export const isInspectionModalOpenState = atom(false);

export const inspectionModalFeedState = atom(null);

export const imageInfoSerialState = atom(null);

export const isTestInfoModalOpenState = atom(false);

export const expandImageInfoSerialRowTrigger = atom(false);

export const riskAwarenessShouldUpdateState = atom(false);

export const isOperatorModalOpenState = atom(false);

export const operatorsState = atom([]);

export const operatorsMapState = atom({});

export const currentOperatorState = atom(null);

export const expectedSubstancesState = atom([]);

export const mapReprocessTriggerState = atom(false);